<template lang="html" >

    <section class="carrousel-2 carrousel-expo" v-if="dataContent && Object.values(dataContent).length != 0">
        <h2 class="d-inline-block w-100">GALERÍA:</h2>
        <agile :options="myOptions" ref="carousel" class="carrousel-home">
            <div v-for="(data, key) in dataContent" :key="key" @click="openPopUp(data.name,data.description, data.image.url)">
                <img class="img-expo" :src="data.image.url" :alt="data.description">
            </div>
        </agile>
    </section>

    <section class="mask-popup"  v-if="popup && dataContent && Object.values(dataContent).length != 0">
        <div class="container-popup">
            <div class="container shadow">
                <button @click="closePopup" class="close">CERRAR</button>
                <img class="img-main" :src="urlImageSelected" :alt="descriptionImageSelected">
                <p class="title">
                    {{titleSelected}}
                </p>
                <p class="description">
                    {{descriptionImageSelected}}
                </p>
                <div class="carrousel-2 carrousel-expo">
                    <agile :options="myOptionsPopup" ref="carousel" class="carrousel-home">
                        <div v-for="(data, key) in dataContent" :key="key">

                            <img class="img-expo" :src="data.image.url" :alt="data.description" @click="openPopUp(data.name,data.description, data.image.url)" />
                          
                    </div>
                    </agile>
                </div>

            </div>
        </div>
    </section>

</template>

<script lang="js">
    import {
        VueAgile
    } from "vue-agile";
    export default {
        name: 'carrousel-2',
        components: {
            agile: VueAgile
        },
        props: ['dataContent'],
        mounted() {

        },
        data() {
            return {
                popup: false,
                descriptionImageSelected: '',
                urlImageSelected: '',
                titleSelected:'',
                myOptions: {
                    responsive: [{
                        breakpoint: 900,
                        settings: {
                            navButtons: true,
                            slidesToShow: 3,
                            dots: false,
                            centerMode: false,
                            unagile: false,
                            autoplay: false,
                        },
                    }, {
                        breakpoint: 800,
                        settings: {
                            navButtons: false,
                            slidesToShow: 2,
                            dots: true,
                            unagile: false,
                            autoplay: false,
                        },
                    },
                    {
                        breakpoint: 700,
                        settings: {
                            navButtons: false,
                            slidesToShow: 2,
                            dots: true,
                            unagile: false,
                            autoplay: false,
                        },
                    }, {
                        breakpoint: 0,
                        settings: {
                            navButtons: false,
                            slidesToShow: 1,
                            dots: true,
                            autoplay: false,
                        },
                    },],
                },
                myOptionsPopup: {
                    responsive: [{
                        breakpoint: 900,
                        settings: {
                            navButtons: true,
                            slidesToShow: 5,
                            dots: false,
                            centerMode: true,
                            unagile: false,
                            autoplay: false,
                        },
                    }, {
                        breakpoint: 800,
                        settings: {
                            navButtons: false,
                            slidesToShow: 2,
                            dots: true,
                            unagile: false,
                            autoplay: false,
                        },
                    },
                    {
                        breakpoint: 700,
                        settings: {
                            navButtons: false,
                            slidesToShow: 2,
                            dots: true,
                            unagile: false,
                            autoplay: false,
                        },
                    }, {
                        breakpoint: 0,
                        settings: {
                            navButtons: false,
                            slidesToShow: 1,
                            dots: true,
                            autoplay: false,
                        },
                    },],
                },
            }
        },
        methods: {
            openPopUp: function (title,element, url) {
                this.urlImageSelected = url
                this.descriptionImageSelected = element
                this.titleSelected = title
                this.popup = true;
            },
            closePopup: function () {
                this.popup = false;
            }
        },
        computed: {

        }
    }


</script>

<style scoped lang="scss">
    @import "@/styles/general.scss";

    h2 {
        margin-bottom: -30px;
    }

    .carrousel-2 {
        padding-bottom: 0px;
        margin-bottom: 0px !important;

        .container-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* padding: 60px 0px; */
            position: relative;
            width: 100%;
            height: 380px;

            .box-card {
                background: $blue;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                color: white;
                padding: 30px 10% 30px 100px;
                min-height: 300px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                transition: all 0.8s ease-in-out;
                width: 55%;
                margin: 0 auto;
                transform: scale(1);
                border-radius: 13px;
                position: absolute;
                left: 2;
                z-index: 1;
                height: 380px;
                /* &:hover {
            transition: all 0.8s ease-in-out;
            transform: scale(1.1);
            z-index: 8;
            position: relative;
            border-radius: 13px;
            box-shadow: 0px 4px 30px rgba(0, 105, 180, 0.3)
          } */

                &:nth-of-type(2n) {

                    background-color: white;
                    color: $blue;
                    position: absolute;
                    right: 30px;
                    width: 50%;
                    z-index: 1;
                    height: 250px;
                    box-shadow: 0px 4px 30px rgba(0, 105, 180, 0.3);
                    left: initial;
                    padding: 30px 100px 30px 50px;

                    .title {

                        &:after,
                        &:before {
                            background: white;
                        }
                    }

                }

                .super-title {
                    line-height: 0.1em;
                    font-size: 5em;
                    font-weight: bold;
                    color: white;
                    opacity: 0.4;
                    width: auto;
                    display: inline-block;
                    font-family: "Frank Ruhl Libre", serif;
                    text-align: right;
                }

                &.bg {
                    color: white;

                    .title {

                        &:after,
                        &:before {
                            background: white;
                        }
                    }
                }


                .description {
                    padding-bottom: 20px;
                }


            }
        }



    }

    
</style>