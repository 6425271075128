<template lang="html">

  <section class="expositions">
    <hero-global img="/img/expositions/expositions-1.svg" title="Exposiciones" />
    <div class="box-content container">
      <ul class="menu-list container">
        <li v-for="(exposition, key) in Object.values(store.expositions).slice(0,10)" :key="key" :value="key">
          <router-link :class="[idParams == exposition.id ? 'router-link-active' : '']" :to="{
            name: 'exposiciones-vista',
            params: {
              slug: exposition.slug
            }
          }" title="Ver {{exposition}}" alt="Ver {{exposition}}">
            {{ exposition.name }}
          </router-link>
        </li>
      </ul>
      <div class="content container" v-if="expositionSelected">
        <div class="grid-full">
          <div class="grid left">
            <ribbon text="Exposición" class="m-bottom-20-px" />
            <img :src="expositionSelected.image.url" alt="imagen exposición"/>
          </div>
          <p class="subtitle" v-if="expositionSelected.description">{{expositionSelected.description}}</p>
          <template v-if="this.store.easyRead.active">
            <EditorJs :object="expositionSelected.easy_reader" />
          </template>
          <template v-else>
            <EditorJs :object="expositionSelected.normal_reader" />
            <EditorJs :object="expositionSelected.presentation" />
          </template>

          <CarrouselExpo class="container m-bottom-40-px" :dataContent="expositionSelected.images" />
        </div>

      </div>
    </div>
    <carrousel-2 class="container m-bottom-40-px" :dataContent="$tm('translations.carrouselData.cermi')" />
    <other-links-interest class="links-interest-box container" />
  </section>
</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import CarrouselExpo from "@/components/Global/CarrouselExpo";
  import moment from 'moment'
  import { contentStore } from '@/stores/contents'
  import EditorJs from "@/components/Global/editorJs.vue"
  import Ribbon from "@/components/Global/Ribbon";
  export default {
    name: 'expositions',
    props: ['id', 'slug'],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      Carrousel2,
      CarrouselExpo,
      EditorJs,
      Ribbon
    },
    setup() {
      const store = contentStore();
      store.loadExpositions();
      return {
        store,
      }

    },
    mounted() {
    },
    data() {
      return {

      }
    },
    methods: {
      getExpositionsBySlug() {
        let t = this;
        this.store.loadExposition(
          {
            slug: this.slug,
          }
        ).then(function (result) {
          result
        });
      },
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      }
    },
    computed: {
      expositionSelected() {
        let exposition = null
        if (this.store.expositions[this.slug]) {
          exposition = this.store.expositions[this.slug]
        } else {
          exposition = this.getExpositionsBySlug()
        }
        return exposition

      }

    },
    watch: {

    }
  }


</script>

<style scoped lang="scss">
  .box-date {
    p {
      font-weight: 300;
      padding-left: 30px;
      margin-bottom: 15px;
    }

    .category-location {
      background-size: 18px;
    }
  }
</style>